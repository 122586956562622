import {Col, Layout, Row} from "antd";
import {Content, Footer, Header} from "antd/es/layout/layout";
import './MainLayout.css';
import {useCallback, useEffect, useState} from "react";
import LineUp from "./pages/LineUp";
import Info from "./pages/Info";
import {FacebookFilled, InstagramFilled, TwitterOutlined} from "@ant-design/icons";
import History from "./pages/History";
import {useSearchParams} from "react-router-dom";
import Tekenwedstrijd from "./pages/Tekenwedstrijd";
import Tombola from "./pages/Tombola";
import Sponsors from "./pages/Sponsors";
import HomeV2 from "./pages/HomeV2";

const underConstruction = false;

const MainLayout = () => {

    const onHomeBandClick = useCallback((lineUp, activeBand) => {
        setCurrentPage(<LineUp activeLineUp={lineUp} activeBand={activeBand}/>)
        setActiveMenu("lineUp");
    }, [])

    const onInfoTileClick = useCallback((page) => {
        if (page === 'tekenwedstrijd') {
            setCurrentPage(<Tekenwedstrijd/>)
            window.scrollTo(0,0)
        } else if (page === 'tombola') {
            setCurrentPage(<Tombola/>)
            window.scrollTo(0,0)
        } else if (page === 'info') {
            handleMenuClick(<Info/>, "info");
        }
    }, [])

    const [currentPage, setCurrentPage] = useState(<HomeV2 onBandClick={onHomeBandClick} onInfoTileClick={onInfoTileClick}/>);
    const [activeMenu, setActiveMenu] = useState("home");
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const page = searchParams.get("main");
        if (page === 'tekenwedstrijd') {
            setCurrentPage(<Tekenwedstrijd/>)
        } else if (page === 'tombola') {
            setCurrentPage(<Tombola/>)
        }
        setSearchParams();
    }, [searchParams])

    const handleMenuClick = useCallback((newPage, menuName) => {
        setCurrentPage(newPage);
        setActiveMenu(menuName)
        window.scrollTo(0,0)
    }, [])

    const renderMainSite = () => {
        return <>
            <Header className={'header'}>
                <Row>
                        <Col xl={1} lg={2} md={6} xs={6} onClick={() => handleMenuClick(<HomeV2 onBandClick={onHomeBandClick} onInfoTileClick={onInfoTileClick}/>, "home")} className={activeMenu === 'home' ? "activeMenuItem" : "menuItem"}>HOME</Col>
                        <Col xl={1} lg={2} md={6} xs={6} onClick={() => handleMenuClick(<LineUp/>, "lineUp")} className={activeMenu === 'lineUp' ? "activeMenuItem" : "menuItem"}>LINE-UP</Col>
                        <Col xl={1} lg={2} md={6} xs={6} onClick={() => handleMenuClick(<Info/>, "info")} className={activeMenu === 'info' ? "activeMenuItem" : "menuItem"}>INFO</Col>
                        <Col xl={1} lg={2} md={6} xs={6} onClick={() => handleMenuClick(<History/>, "history")} className={activeMenu === 'history' ? "activeMenuItem" : "menuItem"}>HISTORY</Col>
                        {/*<Col onClick={() => handleMenuClick(<Contact/>)} style={{cursor: "pointer"}}>CONTACT</Col>*/}
                </Row>
            </Header>
            <Content style={{marginTop: "64px"}}>
                <Row className={"infoRow"}>
                    <Col span={8} className={"info"}>ZON 10 NOV 2024</Col>
                    <Col span={8} className={"info"}>AANSTEKEN HOLOLOOL - 19U</Col>
                    <Col span={8}>
                        <Row className={"socials"}>
                            <Col className={"socialCol"}>
                                <a href={"https://www.facebook.com/HololoolVuurfestival"} target={"_blank"} style={{color: "white"}}>
                                    <FacebookFilled style={{fontSize: "20px", cursor: "pointer"}}/>
                                </a>
                            </Col>
                            <Col className={"socialCol"}>
                                <a href={"https://www.instagram.com/hololool_fest/"} target={"_blank"} style={{color: "white"}}>
                                    <InstagramFilled style={{fontSize: "20px", cursor: "pointer"}}/>
                                </a>
                            </Col>
                            <Col className={"socialCol"}>
                                <a href={"https://twitter.com/hololool_fest"} target={"_blank"} style={{color: "white"}}>
                                    <TwitterOutlined style={{fontSize: "20px", cursor: "pointer"}}/>
                                </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{marginTop: "33px"}}>
                    <Col span={24}>
                        <div className={'body'}>
                            {currentPage}
                        </div>
                    </Col>
                </Row>
            </Content>
            <Footer className={'sponsors'} style={{textAlign: "center"}}>
                <p><span style={{fontFamily: "Vivaldi", fontSize: "24px"}}>Hololool Vuurfestival</span></p>
                <p>i.s.m.: Chiro Zonhoven Centrum, KSA Zonhoven, Chiro Meisjes & Jongens Halveweg</p>
                <p>Met de steun van</p>
                <Sponsors/>
            </Footer>
        </>
    }

    return (
        <Layout className={'layout'}>
            {renderMainSite()}
            <Footer className={'footer'}>
                © {new Date().getFullYear()} Hololool Vuurfestival
            </Footer>
        </Layout>
    );
}

export default MainLayout;