import {Col, Row, Typography} from "antd";
import "./History.css";

const {Title, Text} = Typography;

const History = () => {
    return (
        <div className={"historyBackground"}>
            <Row>
                <Col lg={{span: 12, offset: 6}} md={{span: 24}} xs={{span: 24}} className={"historyTile"}>
                    <Title level={1}>Hololool en de dertien dagen</Title>
                    <Text>
                        Dit verhaal brengt het Zonhovense woord “Hololool” in verband met vermoedelijk langdurige Germaanse invloeden. In de Germaanse en Keltische mythologie komen interessante dingen voor. We moeten waarschijnlijk een goede tweeduizendvijfhonderd jaar terug in de tijd gaan. Het is de tijd van de Noorse, Germaanse en Keltische bloeiperiode in Noordwest Europa, de ijzertijd. Men telde manen in plaats van maanden. Wij weten nu dat, reeds lang voor de Romeinen onze streken veroverden, er een gedegen kennis was van astronomie. Getuige daarvan zijn tal van observatoria, meestal in de vorm van steencirkels, zoals het bekende Stonehenge.
                        Immers, toen de mensen zich vestigden als landbouwers, was er enige nood aan voorspelbaarheid, met betrekking tot zaaien en planten bijvoorbeeld.
                        <br/><br/>
                        Indien men manen telt in plaats van maanden -een synodische maand is negenentwintig dagen en enkele uren lang- dan zijn er in verhouding tot de driehonderdvijfenzestig dagen in een jaar ongeveer dertien dagen te weinig. Wanneer we er bovendien vanuit gaan dat begin en einde van het jaar  samenvielen met de winterzonnewende (23 december), situeren zich deze dertien dagen in de donkerste periode vóór Kerstmis. Wat er in die dertien dagen gebeurde wordt duidelijk uit tal van legenden, mythen en volkse overleveringen. Om te beginnen was men destijds uiterst bang voor de “Wilde Jacht”.<br/><br/>
                        ‘s Nachts raasde een angstaanjagend dodenleger door de lucht, onder luid gebulder en gejoel (dit woord verdient straks alle aandacht), van ruiters op vurige zwarte paarden, aangevoerd door de god Wodan/Odin. Tenminste in de Noorse legenden is dit zo, want in de Germaanse overlevering is het de vrouw van Wodan die deze wilde horde aanvoert. Haar naam is Holda. Het aantal  variaties in de naam van deze godin is aanzienlijk, maar ééntje springt er uit: “Holle”. Denk aan het sprookje van “Frau Holle”. De ruiters in de Wilde Jacht noemde men daarom ook wel “Holders”.<br/><br/>
                        Men kan zich voorstellen dat in de donkerste periode van het jaar, wanneer de zon, zeker in Scandinavië (waar ze dan nauwelijks boven de horizon klimt), quasi op sterven na dood is, de bijgelovige bevolking liever binnen blijft en zich bij het haardvuur schaart. Temeer omdat de hoofdrolspelers van de Wilde Jacht de reputatie hadden van strenge strafrechters. Wie zich niet goed had gedragen werd door de Wilde Jacht opgepakt en berecht. In IJslandse mythen werden stoute kinderen bestraft door kwelkabouters, de “Jolasveinar”, dertien (!) in getal. Zij kwamen uit de bergen, dertien dagen voor Jul, en verdwenen dan weer tegen driekoningen.
                        Er zijn ook  legenden waarin zwart geverfde “Wilde Jachters” via de schoorsteen binnendrongen om kinderen te stelen.<br/><br/>
                        Men sloot zich twee weken op, als het ware. Er moest dus voldoende brandhout aanwezig zijn. Daarom werd een hele boom -of tenminste een hele stronk- in huis gehaald. Dit nam een folkloristische plaats in tijdens de komst van het lichtfeest, later Kerstmis, de winterzonnewende op 23 december, het einde van de bange dagen. Zou die boom/stronk binnen mooi versierd worden? Traditioneel heet dit het joelblok, een stronk die de hele donkere dertien dagen brandend moest gehouden worden en die men bovendien ritueel moest aansteken met spaanders van het blok van het jaar daarvóór.<br/><br/>
                        Er moest ook voldoende voedsel voorradig zijn voor twee weken, voedsel dat binnen in de warmte eetbaar blijft. Koeken en broden zijn goed houdbaar en wanneer extra lekker gemaakt, kunnen ze dienen als zoethoudertjes voor de kids die uiteraard ook binnen moesten blijven. De vrees voor de zwarte knechten van Wodan/Holle was bovendien zo intens dat er koeken en broden in de vorm van een mens werden gemaakt. Men hoopte dat deze dan desgevallend  door de Holders zouden meegenomen worden. De vergelijking met de Sinterklaaskoeken en speculoosmannen ligt hier voor de hand.
                    </Text>
                    <Title level={2}>Sinterklaas, St.-Maarten en Holda</Title>
                    <Text>
                        De zwarte knechten doen denken aan Berserkers. De Romeinse geschiedschrijver Tacitus schrijft over krijgers die na bepaalde rituelen in een roes van onoverwinnelijkheid kwamen, naakt en zwart geverfd ten strijde trokken en berucht waren om hun woeste razernij. Waarschijnlijk baseert Tacitus zich op legenden, al zou ook de overwinning van Arminius op de Romeinse generaal Varus in het jaar negen aan deze Germaanse “black ops” te danken zijn. Sommige bronnen schrijven deze krijgers aan Wodan toe, wiens naam in verband wordt gebracht met het woord “woede”. Wodan (de Germaanse versie van Odin) rijdt trouwens op een schimmelpaard door de lucht en over daken, heeft een lange witte baard, een lange rode mantel, een staf met een gekrulde, met eikels versierde slang en zwarte knechten die stoute mensen meenemen in een zak. Op de Waddeneilanden bestaat nog een traditie waar als “Sunneklazen” verklede mannen woest door de straten rennen. Vrouwen en kinderen moeten binnen blijven of ze krijgen ransel. Tussendoor: we mogen er volgens mij met gerust hart vanuit gaan dat er met dié zwartepieten helemaal geen racisme gemoeid is. In elk geval is de gelijkenis met Sinterklaas opvallend. De “schimmelrijder” komt in andere mythen ook op dezelfde manier voor.<br/><br/>
                        Martinus van Tours, St.-Maarten, wordt ook afgebeeld op een schimmel, droeg een rode mantel en strafte stoute mensen. Al waren het in dat geval heidenen waarvan hij als christelijk bisschop de altaren en tempels vernielde. Toen hij de helft van zijn rode mantel aan een dakloze schonk was hij nog Romeins officier en geen bisschop. Het St.-Maartenfeest wordt op 11 november gevierd. Om één of andere reden moest de oogst dan binnengehaald zijn. Wij trachten te achterhalen waarom dat zo was en waarom de laatste weken voor Kerstmis deze op Wodan gebaseerde figuren opduiken. Maar eerst wat plantkunde.<br/><br/>
                        Wodans staf met de gekrulde slang is versierd met eikels. Wodan als god van de zomer wordt vereenzelvigd met de zomereik. In de Keltische mythologie delft bijvoorbeeld bij de intrede van de herfst de “eikkoning” het onderspit tegen zijn broer de “hulstkoning”. In de Germaanse versie speelt het botanisch aspect op het eerste zicht geen rol. Echter, we moeten even stilstaan bij het volgende.<br/><br/>
                        Zo varieert de naam van Holda nogal wat: Huld, Huldra, Hulle, Holle…zijn namen die ook met hulst lijken te maken te hebben, al is het in Duitsland de Vlierstruik die met de eer gaat lopen (Holderbusch) en Limburgers kennen nog het woord “Hullentul” voor vlier. In het Engels echter is hulst weer: “Holly”, vandaar Holidays. Het mag evident zijn dat, wanneer de eiken hun dorre bladeren laten vallen, het félgroene loof van de hulst de aandacht trekt. Maar er is nog een andere eigenschap die hulst interessant maakt in dit verhaal. Hout van de Ilex Aquifolia is uiterst geschikt voor de productie van houtskool, omdat deze bij verbranding veel heter wordt. Dit was belangrijk voor het winnen van ijzer(erts). De naam “ijzeroer” is misschien toepasselijker, want dit ontstaat door stagnatie in moerasgebieden en bevat voor een kwart ijzer.<br/><br/>
                        Holda is bovendien de godin van het “Wiel”. Met dit wiel wordt de cyclus van de zon en de seizoenen bedoeld, een astronomisch wiel dus. Het Oudnoorse woord voor wiel is “Hjól”, later werd het Hvél, waarin de Nederlandse woorden “wiel” en “velg” te ontdekken zijn. Hjól en Holle lijken sterk op elkaar. De moderne naam voor de Kerstperiode in Scandinavië is  Jul, (Keltisch: Yule)hetgeen allemaal wijst op een belangrijke rol die Holle speelde.
                    </Text>
                    <Title level={2}>Vuurfeest</Title>
                    <Text>
                        De maand vóór de Joeltijd (hier is het woord) werden allerlei rituelen uitgevoerd met vuren. Kransen van hout en stro komen daarbij vaak voor. Zo werden deze “wielen” brandend van de heuvels het veld in gerold. Misschien was het daarom wel raadzaam eerst de oogst binnen te halen. Maar waarom één maand? Van de Kelten is bekend dat zij het feest “Samhain” op 1 november vierden. Het Keltische nieuwjaar heeft  blijkbaar meer te maken met de oogstperiode. Het Germaanse Joelfeest (Jul) is Kerstmis. Er moest dus nog iets meespelen dat ons op 10 of 11 november zou brengen.<br/><br/>
                        Advent, een maand met vuur en kransen! Dat de Christelijke marketingmachine vroeger gretig gebruik heeft gemaakt van heidense feestdagen om haar eigen feestkalender op te enten, mag intussen iedereen bekend zijn.  De katholieke kerk gaf Sint-Maarten in de Middeleeuwen meer gewicht door op 11 november een veertigdaagse vasten in te stellen; in de 'elfde van de elfde' van de carnavalsviering klinkt dit nog door. Sint-Maarten ging hierdoor fungeren als een Nieuwjaarsdag, die werd aangegrepen om pachten af te rekenen en personeel in te huren. Boeren vierden Sint-Maartensavond ook lange tijd uitbundiger dan Oud en Nieuw. Bovendien begint ook op die dag het nieuwe liturgische jaar van de kerk.<br/><br/>
                        “Hololool” zal voor niet-Zonhovenaren Chinees wezen, tenminste wanneer we de afgelopen twintig jaren niet meerekenen. Hololool is namelijk als evenement op tien november intussen tot ver buiten de gemeentegrenzen bekend. In de wijken van Zonhoven werden vroeger door de jeugd weken van tevoren brandbare materialen verzameld. Hout, maar ook oude autobanden (!) Vaak werden ook materialen die brandbaar waren, ontvreemd. Dat was “De jeugd van tegenwoordig” zou je denken? Jan ter Gouw citeert een keur uit Dordrecht waaruit blijkt hoe het daar in 1443 aan toeging. 'Jonge boefkens' hadden op de avond ervoor 'veel onredelikheden' bedreven door op de straten Sint-Maartensvuren te stoken, waartoe zij zitbanken, deuren en uithangborden bij huizen in de omgeving hadden afgebroken en aldus 'den luden' veel schade hadden berokkend. Er werd met zware straffen gedreigd.<br/><br/>
                        Elke wijk had een eigen “Hololool” en wedijverde om de grootste en hoogste vuurstapel te bouwen. Er werd ’s nachts gewaakt om te vermijden dat de zo prestigieuze “hololool” door anderen eerder werd aangestoken. Indien dat tóch gebeurde was dat een afgang. Uiteraard was zulk een ongecontroleerd spelen met vuur gevaarlijk, en bovendien erg vervuilend. Achteraf moest dan alles ook nog opgeruimd worden. Niet zelden zorgde het gemeentebestuur daarvoor. Uiteindelijk -ik schat zo rond 1978- is de gemeente Zonhoven overgegaan tot het zelf organiseren van een centrale hololool, waarbij de wijkversies werden verboden. Nu is hololool een drukbezocht groot cultureel evenement, met fakkeltochten, animatie, optredens en vuurwerk.
                        <br/><br/>
                        De naam “hololool” is helaas, ook voor de inwoners van Zonhoven, steeds een raadsel geweest.<br/><br/>
                        Vertrekkend van de winterzonnewende van 23 december tellen we de dertien bange dagen af, dat brengt ons op 10 december. Wanneer we advent meerekenen, komen we op 10 november, de dag van Hololool.<br/><br/>
                        “Hololool, geef mij een kool, om Sint Maarten zijn tenen te warmen!” zegt het versje. Wanneer er sprake is van “Kool” lijkt dat toch te betekenen dat het niet zomaar een vuur was, maar eerder een vuurstapel om houtskool te winnen. Wanneer je op de Heuven in Zonhoven begint te graven, kom je op minder dan één meter diepte al in het grondwater, zowel als in het ijzeroer. Dit is duidelijk een  uitgelezen plek om ijzer te delven. Er werd eeuwen later zelfs massaal ijzererts vanuit Zonhoven naar Duitsland geëxporteerd. Misschien was het vroeger wel de eerste hulst (Holle)-o-lool van het seizoen? Om enerzijds de slaagkans van een goede hulst-houtskoolproductie te verhogen, en anderzijds de komst van de Wilde Jacht en de dertien verloren dagen tegen te houden, kan dus een Germaans ritueel behouden zijn gebleven waarbij de dorpelingen luid joelend rond het vuur  dansen: Holle! Holle! Holle! Is het toeval dat de Kerstman “Ho, Ho, Ho!” roept, wanneer hij net als Wodan/Holle door de lucht scheert? De drielettergrepige uitroep komen we in het teken van Jul ook tegen.
                        Op Youtube is <a href="https://www.youtube.com/watch?v=8LVr5G50GH4">dit fragment</a> te vinden:<br/><br/>
                        De drie kerstmannen beginnen met een meerstemmig “Jul Jul Jul”, “Jul Jul Jul”. De vergelijking met “Hol Hol Hol” is opvallend, zo niet doorslaggevend.<br/><br/>
                        Wij kunnen besluiten met het gegrond vermoeden dat de naam “Hololool” direct gelinkt is met de Germaans-Keltische aanloop naar Kerstmis. Vermits het woord alleen in Zonhoven voorkomt duidt dat erop dat daar de traditie ouder is, of tenminste dieper geworteld. Dit kan te maken hebben met een duizenden jaren oude geschiedenis van houtskoolproductie voor de ijzerwinning.
                        <br/><br/>
                        <i>Koen Willems - Bronnen: Wikipedia.</i>
                    </Text>
                </Col>
            </Row>
            <Row>
                <col span={24} style={{backgroundColor: "#434343", height: "10px", width: "100%"}}></col>
            </Row>
        </div>
    )
};

export default History;