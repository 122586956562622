import "./Tile.css"
import PropTypes from "prop-types";

const Tile = ({title, backgroundImage, handleClick}) => {

    return (
        <div className={"tile-wrapper"} style={{backgroundImage: `url(${backgroundImage})`}} onClick={handleClick}>
            <div className={"tile-header"}>
                {title}
            </div>
            <div className={"tile-content"} style={{cursor: "pointer"}}></div>
        </div>
    )

}

Tile.propTypes = {
    title: PropTypes.string.isRequired,
    backgroundImage: PropTypes.string,
}

export default Tile;