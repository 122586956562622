import "./Tonbola.css";
import {Col, Row, Table, Typography} from "antd";

const {Title, Text} = Typography;

const tombolaCol = [
    {title: 'Prijs', dataIndex: 'priceNr', key: 'priceNr'},
    {title: 'Lotje', dataIndex: 'lot', key: 'lot'},
];

const tekenCol = [
    {title: 'Prijs', dataIndex: 'priceNr', key: 'priceNr'},
    {title: 'Naam', dataIndex: 'name', key: 'name'},
    {title: 'School', dataIndex: 'school', key: 'priceNr'+123},
];

const tombolaDs = [
    {priceNr: '1ste', lot: '6566'},
    {priceNr: '2de', lot: '7388'},
    {priceNr: '3de', lot: '3103'},
    {priceNr: '4de', lot: '1153'},
    {priceNr: '5de', lot: '3593'},
    {priceNr: '6de', lot: '2061'},
    {priceNr: '7de', lot: '4000'},
    {priceNr: '8ste', lot: '3998'},
    {priceNr: '9de', lot: '7662'},
    {priceNr: '10de', lot: '6311'},
]

const tekenDs = [
    {priceNr: 'Efteling', name: 'Nora Beulen', school: 'De Zonnewijzer - 1B'},
    {priceNr: 'Kleuters', name: 'Lio Torfs', school: 'De St@rtbaan - K2A'},
    {priceNr: '1ste graad', name: 'Ella Kenis', school: 'De Zonnewijzer - 1D'},
    {priceNr: '2de graad', name: 'Demi Nys', school: 'De Lettermolen - 4B'},
    {priceNr: '3de graad', name: 'Leoni Van der Linden', school: 'De Toverfluit - 6C'},
]

const Tekenwedstrijd = () => {
    return (
        <div className={"background"}>
            <Row>
                <Col lg={{span: 12, offset: 6}} md={{span: 24}} xs={{span: 24}} className={"tombolaTile"}>
                    <Title>Uitslag tombolawedstrijd</Title>
                    <Table dataSource={tombolaDs} columns={tombolaCol} pagination={false} style={{width: '80%', margin: 'auto'}}/>
                </Col>
            </Row>
            <Row>
                <Col lg={{span: 12, offset: 6}} md={{span: 24}} xs={{span: 24}} className={"tombolaTile"}>
                    <Title>Uitslag tekenwedstrijd</Title>
                    <Table dataSource={tekenDs} columns={tekenCol} pagination={false} style={{width: '80%', margin: 'auto'}}/>
                </Col>
            </Row>
            <Row>
                <Col lg={{span: 12, offset: 6}} md={{span: 24}} xs={{span: 24}} className={"tombolaTile"}>
                    <Title>Afhalen prijzen</Title>
                    <Text>Bovenstaande prijzen zijn af te halen na telefonische afspraak, voor 20 december op nr.: 0477 657 687</Text>
                </Col>
            </Row>
            <Row>
                <col span={24} style={{backgroundColor: "#434343", height: "10px", width: "100%"}}></col>
            </Row>
        </div>
    )
};

export default Tekenwedstrijd;