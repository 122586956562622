import "./Info.css";
import {Col, Image, Row, Typography} from "antd";
import rugzak from "../../images/pictogrammen/rugzak.png";
import handtas from "../../images/pictogrammen/handtas.png";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {useCallback} from "react";

const {Title, Text} = Typography;

const Info = () => {

    const screens = useBreakpoint();

    const getImageWidth = useCallback(() => {
        if (screens?.xs) {
            return 250;
        } else {
            return 600;
        }
    }, [screens])

    return (
        <div className={"background"}>
            <Row>
                <Col lg={{span: 12, offset: 6}} md={{span: 24}} xs={{span: 24}} className={"infoTileLeft"}>
                    <Title>Hololool, terrein</Title>
                    <Text>
                        Hololool 2024 zal nog meer ruimte bieden voor de bezoekers. Vooral het terras rond de vuurhoop is uitgebreid. Zo kunnen nog meer mensen het vuur bewonderen vanop de eerste rij.
                        De cocktailbar is verplaatst. Deze kunnen jullie vinden aan fuiftent.
                        Bekijk zeker het plannetje hieronder zodat jullie dadelijk jullie favoriete plek terugvinden.
                    </Text>
                    <div style={{textAlign: 'center', paddingTop: '20px'}}>
                        <Image src={"images/terrein_2024.png"} width={getImageWidth()}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={{span: 12, offset: 6}} md={{span: 24}} xs={{span: 24}} className={"infoTileLeft"}>
                    <Title>Eten & Drinken</Title>
                    <Text>
                        Onze dranktogen zijn nooit ver weg. We bieden frisdrank, bier en diverse (fruit)jenevers
                        aan.<br/>
                        Voor een lekkere friet of snack moet je bij de Foodcourt zijn, deze vind je langs de feesttent.<br/>
                        Eten en drinken worden betaald via papieren consumptiebonnen. U kan ze verkrijgen aan de
                        kassa's.<br/>
                        Om milieuhinder te beperken, van een organisatie zoals Hololool, werken we dit jaar voor eerst met ECO COINS, het systeem staat hieronder uitgelegd.
                        <div style={{textAlign: 'center', paddingTop: '20px'}}>
                            <Image src={"images/ecocoins.png"} width={getImageWidth()}/>
                        </div>
                    </Text>
                </Col>
            </Row>
            <Row>
                <Col lg={{span: 12, offset: 6}} md={{span: 24}} xs={{span: 24}} className={"infoTileLeft"}>
                    <Title>Fakkeltocht</Title>
                    <Text>
                        De startlocatie blijft nog steeds dezelfde: Basisschool de Zonnewijzer. De deelnemers komen de speelplaats op vanaf de ovonde. Daar zullen ook de fakkels verkocht worden. Iedereen schuift daarna door naar de speelplaats van de school.<br/>
                        Bij het vertrek zullen de deelnemers via de tweede poort de speelplaats verlaten. Zo trekt de tocht, over het fietspad, richting de post en vervolgens richting centrum.<br/>
                        Vergeet na de fakkeltocht zeker niet je fakkel binnen te brengen bij de infostand. Je krijgt je waarborg op de fakkel daar terug.<br/>
                    </Text>
                </Col>
            </Row>
            <Row>
                <Col lg={{span: 12, offset: 6}} md={{span: 24}} xs={{span: 24}} className={"infoTileLeft"}>
                    <Title>Veiligheid</Title>
                    <Text>
                        Vuur blijft gevaarlijk en onvoorspelbaar. Dus wees verstandig en respecteer de aangebrachte afsluitingen. Gedurende het festival blijft de brandweer en het Rode Kruis aanwezig.<br/>
                        Hololool is en blijft een feest. Om dit zo te houden en de enkelingen, die dit niet weten, erop te wijzen, loopt er zowel security als politie op als naast het terrein rond.<br/>
                        <Image src={rugzak} preview={false} style={{paddingRight: "5px", width: "40px"}}/>
                        <Image src={handtas} preview={false} style={{paddingRight: "5px", width: "40px"}}/>
                        Rugzakken en handtassen zijn verboden op het terrein!
                    </Text>
                </Col>
            </Row>
            <Row>
                <Col lg={{span: 12, offset: 6}} md={{span: 24}} xs={{span: 24}} className={"infoTileLeft"}>
                <Title>Snoeiafval</Title>
                    <Text>
                        De opbouw van de brandstapel gebeurt door de gemeente. Zij houden geschikt hout opzij dat  op het containerpark binnenkomt.<br/>
                        Anders gezegd is het verboden  om snoeiafval af te gooien op het terrein. Dit wordt beschouwd als sluikstorten!<br/>
                        De organisatie gaat niet in op verzoeken omtrent snoeiafval!!
                    </Text>
                </Col>
            </Row>
            <Row>
                <Col lg={{span: 12, offset: 6}} md={{span: 24}} xs={{span: 24}} className={"infoTileLeft"}>
                    <Title>Bereikbaarheid</Title>
                    <Text>
                        Het terrein is gelegen aan de Dijkbeemdenweg, langs de Evenementenhal.<br/>
                        Met de wagen via de E314 Brussel-Genk - afrit 29 en vervolgens de N74 richting Hasselt en
                        kruispunt met de Zavelstraat links af. Of komende van Hasselt, de N74 richting Eindhoven, in
                        Zonhoven de wegwijzers Centrum volgen.<br/>
                    </Text>
                    <div style={{textAlign: 'center', paddingTop: '20px'}}>
                        <Image src={"images/omgeving_2024.PNG"} width={getImageWidth()}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <col span={24} style={{backgroundColor: "#434343", height: "10px", width: "100%"}}></col>
            </Row>
        </div>
    )
};

export default Info;