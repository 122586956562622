import "./HomeV2.css";
import {Col, Row} from "antd";
import Tile from "../card/Tile";
import PropTypes from "prop-types";

const tiles = [
    {key: '101', title: 'UITSLAG TOMBOLA & TEKENWEDSTRIJD', imageUrl: '/images/flames.jpg', order: {lg: 1, md: 2, xs: 2}, isTombola: true},
    {key: '102', title: 'FAKKELTOCHT - 18u30', imageUrl: '/images/fakkeltocht.jpg', order: {lg: 3, md: 3, xs: 3}, isInfo: true},
    {key: '103', title: 'DOORLOPEND STRAATTHEATER', imageUrl: '/images/straattheater.jpg', order: {lg: 4, md: 4, xs: 4}, isBand: true, lineUp: "common"},
    {key: '104', title: 'PLUGGED - 19u30', imageUrl: '/images/bands/plugged.png', order: {lg: 5, md: 5, xs: 5}, isBand: true, lineUp: "ambiance", activeBand: "al1"},
    {key: '105', title: 'FUNKY \'D\' - 22u', imageUrl: '/images/bands/funkyd.jpg', order: {lg: 6, md: 6, xs: 6}, isBand: true, lineUp: "ambiance", activeBand: "al2"},
    {key: '109', title: 'KINDERDISCO TOT 21u', imageUrl: '/images/kinderdisco.jpg', order: {lg: 9, md: 9, xs: 9}, isBand: true, lineUp: "common"},
    {key: '110', title: 'FUIFTENT VANAF 21u', imageUrl: '/images/fuiftent_2.jpg', order: {lg: 10, md: 10, xs: 10}, isBand: true, lineUp: "party"},
    {key: '111', title: 'GRANDIOOS VUURWERK - 21u45', imageUrl: '/images/vuurwerk.jpg', order: {lg: 11, md: 11, xs: 11}, isBand: true, lineUp: "common"},
    {key: '112', title: 'ECOCOINS ?!', imageUrl: '/images/ecocoins.png', order: {lg: 12, md: 12, xs: 12}, isInfo: true},
]

const HomeV2 = ({onBandClick, onInfoTileClick}) => {

    return (
        <div className={"background"}>
            <Row gutter={[20,20]} style={{margin: "20px", marginTop: "20px", marginBottom: "20px"}}>
                <Col lg={{span: 8, order: 2}} md={{span: 12, order: 1}} xs={{span: 24, order: 1}} className={"tileWrapper"}>
                    <div className={"tile"}>
                        <div className={"logo"}/>
                    </div>
                </Col>
                {tiles.map(tile => (
                    <Col key={tile.key} lg={{span: 8, order: tile.order.lg}} md={{span: 12, order: tile.order.md}} xs={{span: 24, order: tile.order.xs}}>
                        <Tile title={tile.title} backgroundImage={tile.imageUrl} handleClick={tile.isInfo ? () => onInfoTileClick("info") : tile.isBand ? () => onBandClick(tile.lineUp, tile.activeBand) : tile.isTombola ? () => onInfoTileClick('tombola') : ()=>{}}/>
                    </Col>
                ))}
            </Row>
            <Row>
                <col span={24} className={"divider"}></col>
            </Row>
        </div>
    )
};

HomeV2.propTypes = {
    onBandClick: PropTypes.func.isRequired,
    onInfoTileClick: PropTypes.func.isRequired
}

export default HomeV2;