import './App.css';

import 'antd/dist/antd.min.css';
import MainLayout from "./components/MainLayout";
import {Route, Routes} from "react-router-dom";

const App = () => {
  return (
    <div className={'App'}>
        <Routes>
            <Route path="/" element={<MainLayout/>}/>
        </Routes>
    </div>
  );
}

export default App;
