import "./Tekenwedstrijd.css";
import {Button, Col, Row, Table, Typography} from "antd";
import {DownloadOutlined} from "@ant-design/icons";

const {Title, Text} = Typography;

const columns = [
    {title: 'Leerjaar', dataIndex: 'leerjaar', key: 'leerjaar'},
    {title: 'Tekening', dataIndex: 'file', key: 'file'},
    {title: '', dataIndex: 'action', key: 'action', render: (text, record) => renderDownloadButton(record)},
];

const renderDownloadButton = (record) => {
    return (
        <Button onClick={() => onButtonClick(record.file)} icon={<DownloadOutlined />}>
            Download
        </Button>)
}

const onButtonClick = (fileName) => {
    const fullPath = 'tekenwedstrijd/' + fileName

    // using Java Script method to get PDF file
    fetch(fullPath).then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = fileName;
            alink.click();
        })
    })
}

const dataSource = [
    {leerjaar: 'Kleuters', file: 'kleuters.pdf'},
    {leerjaar: '1ste en 2de', file: '1ste_graad.pdf'},
    {leerjaar: '3de en 4de', file: '2de_graad.pdf'},
    {leerjaar: '5de en 6de', file: '3de_graad.pdf'},
]

const Tekenwedstrijd = () => {
    return (
        <div className={"background"}>
            <Row>
                <Col lg={{span: 12, offset: 6}} md={{span: 24}} xs={{span: 24}} className={"tekenwedstrijdTile"}>
                    <Title>Tekenwedstrijd</Title>
                    <Text>
                        Elk jaar organiseren wij een grote tekenwedstrijd met als hoofdprijs: <b>4 tickets voor de Efteling</b><br/><br/>
                        Breng jouw tekening op 10 november voor 21u naar de infostand.<br/>
                        Iedereen die zijn tekening meebrengt krijgt een gratis drankje. (Zolang de voorraad strekt)<br/>
                        De grote winnaar gaat met het gezin naar de <b>Efteling</b>!<br/><br/>
                        Ben jij je tekening kwijt, of zit je niet op een Zonhovense school ... download dan hier je tekening.<br/><br/>
                    </Text>
                    <Table dataSource={dataSource} columns={columns} pagination={false}/>
                </Col>
            </Row>
            <Row>
                <col span={24} style={{backgroundColor: "#434343", height: "10px", width: "100%"}}></col>
            </Row>
        </div>
    )
};

export default Tekenwedstrijd;